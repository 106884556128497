import React, { Component } from "react";
import _ from "lodash";
import { graphql, Link, useStaticQuery } from "gatsby";
import Layout from "../components/layout";
import Img from "gatsby-image";
import SEO from "../components/seo";
import FilterAsesorias from "../components/filter-asesorias";

import Breadcrumb from "../components/breadcrumb.js";
const { htmlToText } = require("html-to-text");
let dictionary = require("../dic/dic.json");
class Asesorias extends Component {
  constructor (props) {
    super(props);
    this.state = { ubicacion: null, partner: null, industria: null, servicio: null, postName: null, page: null };
    this.updateState = this.updateState.bind(this);
  }

  componentDidMount () {
    function getQueryParamValue (param) {
      const query = window.location.search.substring(1);
      const params = query.split("&");
      for (let i = 0; i < params.length; i++) {
        const pair = params[i].split("=");
        if (decodeURIComponent(pair[0]) === param) {
          return decodeURIComponent(pair[1]);
        }
      }
      return null;
    }
    const posts = this.props.data.allWpPost.nodes;
    const industrias = [];
    const ubicaciones = [];
    const servicios = [];
    const names = [];
    posts.forEach(post => {
      if (post.industrias.industrias !== null) {
        post.industrias.industrias.forEach(industria => {
          if (!industrias.includes(industria)) {
            industrias.push(industria);
          }
        });
      }
      if (post.ubicacion.ubicacion !== null) {
        if (!ubicaciones.includes(post.ubicacion.ubicacion)) {
          ubicaciones.push(post.ubicacion.ubicacion);
        }
      }
      if (post.asesorias.servicios !== null) {
        post.asesorias.servicios.forEach(servicio => {
          if (!servicios.includes(servicio)) {
            servicios.push(servicio);
          }
        });
      }
      if (post.title !== null) {
        if (!names.includes(post.title)) {
          names.push(post.title);
        }
      }
    });
    // const currentURL = new URL(window.location.href);
    // const params = new URLSearchParams(currentURL.search);
    let Ubicacion = this.state.ubicacion;
    let Partner = this.state.partner;
    let Industria = this.state.industria;
    let Servicio = this.state.servicio;
    let PostName = this.state.postName;
    let Page = this.state.page;
    const ubiVal = getQueryParamValue("ubicacion");
    const partnerVal = getQueryParamValue("partner");
    const industrieVal = getQueryParamValue("industria");
    const serviceVal = getQueryParamValue("servicio");
    const postNameVal = getQueryParamValue("postName");
    const pageVal = getQueryParamValue("page");
    if (ubiVal) {
      Ubicacion = decodeURIComponent(ubiVal);
      const valUbi = document.getElementById("buscador-ubi");
      valUbi.value = Ubicacion;
    }
    if (partnerVal) {
      Partner = partnerVal;
    }
    if (industrieVal) {
      Industria = decodeURIComponent(industrieVal);
    }
    if (serviceVal) {
      Servicio = decodeURIComponent(serviceVal);
    }
    if (postNameVal) {
      PostName = decodeURIComponent(postNameVal);
    }
    if (pageVal) {
      Page = pageVal;
    }
    // this.setState({ ubicacion: Ubicacion, industria: Industria, servicio: Servicio, postName: PostName, page: Page });
    this.updateState("ubicacion", Ubicacion);
    this.updateState("partner", Partner);
    this.updateState("industria", Industria);
    this.updateState("servicio", Servicio);
    this.updateState("postName", PostName);
    this.updateState("page", Page);
    const current_class = this;
    // Buscador postName
    const searchName = document.getElementById("buscador-nombre");
    searchName.addEventListener("input", function () {
      current_class.updateState("postName", searchName.value);
    });
    // Fin Buscador postName
    // Buscador Ubicación
    const searchUbi = document.getElementById("buscador-ubi");
    searchUbi.addEventListener("input", function () {
      const currentInput = searchUbi.value;
      const matchingOptions = [];
      // Encuentra las opciones que coinciden con el texto de entrada actual
      for (let i = 0; i < ubicaciones.length; i++) {
        if (ubicaciones[i].toLowerCase().indexOf(currentInput.toLowerCase()) !== -1) {
          matchingOptions.push(ubicaciones[i]);
        }
      }
      // Muestra las opciones coincidentes en una lista desplegable
      const autocompleteList = document.getElementById("autocomplete-list");
      autocompleteList.innerHTML = "";
      for (let j = 0; j < matchingOptions.length; j++) {
        const option = document.createElement("li");
        option.textContent = matchingOptions[j];
        option.classList.add("autocomplete-item");
        option.setAttribute("data-value", matchingOptions[j]);
        autocompleteList.appendChild(option);

        option.addEventListener("click", function () {
          const newUbi = option.getAttribute("data-value");
          // this.setState({ ubicacion: newUbi });
          // current_class.updateState("ubicacion", newUbi);
          current_class.updateState("ubicacion", newUbi);
          updateURI("ubicacion", encodeURIComponent(newUbi));
          searchUbi.value = newUbi;
          const ulElement = document.getElementById("autocomplete-list");
          const childNodes = ulElement.childNodes;
          for (let i = childNodes.length - 1; i >= 0; i--) {
            const childNode = childNodes[i];
            if (childNode.tagName === "LI") {
              ulElement.removeChild(childNode);
            }
          }
        });
      }
    });
    // Fin Buscador ubicación

    // Buscador partner
    const searchPartner = document.getElementById("asesoriaPartner");
    if (Partner === "1") {
      searchPartner.checked = true;
    }
    searchPartner.addEventListener("click", function () {
      if (this.checked) {
        current_class.updateState("partner", "1");
        updateURI("partner", "1");
      } else {
        current_class.updateState("partner", "0");
        updateURI("partner", "0");
      }
    });
    // Fin buscador partner

    // Buscador Servicios
    const searchServices = document.getElementById("servicios_list");
    const childNodesServices = searchServices.childNodes;
    const childNodesServices2 = searchServices.childNodes;
    for (let i = childNodesServices.length - 1; i >= 0; i--) {
      const childNodeService = childNodesServices[i];
      if (childNodeService.tagName === "LI") {
        if (Servicio !== null && Servicio.includes(childNodeService.getElementsByClassName("servicio")[0].name)) {
          childNodeService.getElementsByClassName("servicio")[0].checked = true;
        }
        childNodeService.addEventListener("click", function () {
          let stringService = "";
          for (let i = childNodesServices2.length - 1; i >= 0; i--) {
            const childNodeService2 = childNodesServices2[i];
            if (childNodeService2.getElementsByClassName("servicio")[0].checked) {
              stringService = stringService + childNodeService2.getElementsByClassName("servicio")[0].name + ",";
            }
          }
          stringService = stringService.substring(0, stringService.length - 1);
          current_class.updateState("servicio", stringService);
          updateURI("servicio", encodeURIComponent(stringService));
        });
      }
    }
    // Fin Buscador Servicios
    //toggle menu asesorias
    var filterBigs = document.querySelectorAll(".filtre big");
filterBigs.forEach(function(filterBig) {
  filterBig.addEventListener("click", function() {
    console.log("ENTRA");
    var parent = this.parentNode;
    var content = parent.querySelectorAll(".content");
    content.forEach(function(element) {
      element.classList.toggle("active");
    });
  });
});
    // Buscador sector
    const searchSector = document.getElementById("industria_list");
    const childNodesSector = searchSector.childNodes;
    const childNodesSector2 = searchSector.childNodes;
    for (let i = childNodesSector.length - 1; i >= 0; i--) {
      const childNodeSector = childNodesSector[i];
      if (childNodeSector.tagName === "LI") {
        if (Industria !== null && Industria.includes(childNodeSector.getElementsByClassName("industria")[0].name)) {
          childNodeSector.getElementsByClassName("industria")[0].checked = true;
        }
        childNodeSector.addEventListener("click", function () {
          let stringSector = "";
          for (let i = childNodesSector2.length - 1; i >= 0; i--) {
            const childNodeSector2 = childNodesSector2[i];
            if (childNodeSector2.getElementsByClassName("industria")[0].checked) {
              stringSector = stringSector + childNodeSector2.getElementsByClassName("industria")[0].name + ",";
            }
          }
          stringSector = stringSector.substring(0, stringSector.length - 1);
          current_class.updateState("industria", stringSector);
          updateURI("industria", encodeURIComponent(stringSector));
        });
      }
    }
    // Fin Buscador sector
    // const searchName = document.getElementById("buscador-nombre");
    // searchName.addEventListener("input", function () {
    //   const currentInput = searchName.value;
    //   const matchingOptions = [];
    //   // Encuentra las opciones que coinciden con el texto de entrada actual
    //   for (let i = 0; i < names.length; i++) {
    //     if (names[i].toLowerCase().indexOf(currentInput.toLowerCase()) !== -1) {
    //       matchingOptions.push(names[i]);
    //     }
    //   }
    //   // Muestra las opciones coincidentes en una lista desplegable
    //   const autocompleteList = document.getElementById("autocomplete-list-name");
    //   autocompleteList.innerHTML = "";

    //   for (let j = 0; j < matchingOptions.length; j++) {
    //     const option = document.createElement("li");
    //     option.textContent = matchingOptions[j];
    //     option.classList.add("autocomplete-item-name");
    //     option.setAttribute("data-value", matchingOptions[j]);
    //     autocompleteList.appendChild(option);
    //   }
    // });
    function updateURI (param, value) {
      const paramName = param;
      const paramValue = value;
      const paramString = paramName + "=" + encodeURIComponent(paramValue);
      // Get the current URL
      const currentUrl = window.location.href;
      // Check if the URL already contains parameters
      const separator = currentUrl.includes("?") ? "&" : "?";
      let newUrl = "";
      // Check if @param exists in currentUrl
      if (currentUrl.includes(param)) {
        const urlObj = new URL(currentUrl);
        const searchParams = urlObj.searchParams;
        if (value === "" || (param === "partner" && value === "0")) {
          searchParams.delete(param);
        } else {
          searchParams.set(param, value);
        }
        newUrl = urlObj.origin + urlObj.pathname + "?" + searchParams.toString();
      } else {
        // Build the new URL with the added parameter
        newUrl = currentUrl + separator + paramString;
      }
      // Update the URL without reloading the page
      if (newUrl.substring(newUrl.length - 1) === "?") {
        window.history.pushState({ path: newUrl.substring(0, newUrl.length - 1) }, "", newUrl.substring(0, newUrl.length - 1));
      } else {
        window.history.pushState({ path: newUrl }, "", newUrl);
      }
    }
  }

  updateState (stateName, value) {
    this.setState({ [stateName]: value });
  }

  render () {
    const posts = this.props.data.allWpPost.nodes;
    const industrias = [];
    const ubicaciones = [];
    const servicios = [];
    let numPages = 1;
    let numPosts = 0;
    posts.forEach(post => {
      numPosts++;
      if (numPosts === 8) {
        numPosts = 0;
        numPages++;
      }
      if (post.industrias.industrias !== null) {
        post.industrias.industrias.forEach(industria => {
          if (!industrias.includes(industria)) {
            industrias.push(industria);
          }
        });
      }
      if (post.ubicacion.ubicacion !== null) {
        if (!ubicaciones.includes(post.ubicacion.ubicacion)) {
          ubicaciones.push(post.ubicacion.ubicacion);
        }
      }
      if (post.asesorias.servicios !== null) {
        post.asesorias.servicios.forEach(servicio => {
          if (!servicios.includes(servicio)) {
            servicios.push(servicio);
          }
        });
      }
    });
    // let contador_posts = 0;
    const category = this.props.data.wpCategory;
    const page = this.props.data.wpPage;
    // let main_cut_slug = category.slug;
    // if (category.ancestors !== null) {
    //   main_cut_slug = category.ancestors.nodes[0].slug;
    // }

    const langPage = this.props.pageContext.idioma;
    let slugCategory = category.uri;
    if (slugCategory !== undefined) {
      slugCategory = slugCategory.replace("all/", "");
      slugCategory = slugCategory.replace("category/", "");
    }

    const openGraphslugPage = slugCategory;
    const seo = page.seo;
    dictionary = require("../dic/dic.json");
    const breadcrumb = [];
    if (category.slug !== "asesorias") {
      let categoria = {
        title: category.ancestors.nodes[0].name,
        slug: category.ancestors.nodes[0].slug
      };
      breadcrumb.push(categoria);
      categoria = {
        title: category.name,
        slug: null
      };
      breadcrumb.push(categoria);
    }
    let currentPage = 1;
    let currentUbi = "";
    let currentServices = "";
    let currentIndustries = "";
    let currentPostName = "";
    let isPartner = "0";
    if (this.state.ubicacion !== null) {
      currentUbi = this.state.ubicacion;
    }
    if (this.state.servicio !== null) {
      currentServices = this.state.servicio;
    }
    if (this.state.industria !== null) {
      currentIndustries = this.state.industria;
    }
    if (this.state.postName !== null) {
      currentPostName = this.state.postName;
    }

    if (this.state.page !== null) {
      currentPage = this.state.page;
    }
    if (this.state.partner !== null) {
      isPartner = this.state.partner;
    }

    return (
      <Layout currentPage={category.name} langPage={this.props.pageContext.idioma} clase_pagina={"page-asesorias"}>
        <SEO
          title={seo.title}
          description={seo.metaDesc}
          canonical={slugCategory}
          robotsNofollow={seo.metaRobotsNofollow}
          robotsNoindex={seo.metaRobotsNoindex}
          openGraphSite={seo.opengraphSiteName}
          openGraphType={seo.opengraphType}
          openGraphUrl={openGraphslugPage}
          openGraphImg={
            seo.opengraphImage !== null
              ? seo.opengraphImage.sourceUrl
              : "/images/logo-billage-default-social-share.jpg"
          }
          openGraphImgWidth={
            seo.opengraphImage !== null
              ? seo.opengraphImage.mediaDetails.width
              : 300
          }
          openGraphImgHeight={
            seo.opengraphImage !== null
              ? seo.opengraphImage.mediaDetails.height
              : 300
          }
          dctermsCreated={page.date}
          dctermsModified={page.modified}
          lang={langPage}
          twitterImg={
            seo.twitterImage !== null
              ? seo.twitterImage.sourceUrl
              : "/images/logo-billage-default-social-share.jpg"
          }
          twitterImgHeight={
            seo.twitterImage !== null ? seo.twitterImage.mediaDetails.height : 300
          }
          twitterImgWidth={
            seo.twitterImage !== null ? seo.twitterImage.mediaDetails.width : 300
          }

        />

        <Breadcrumb props={breadcrumb} isChild={category.slug === "asesorias" ? "true" : "false"} isPage="false" />
        <div className="container">
          <div className="row">
            <aside className="col-md-4 col-12 filtercol">
              <h1>Directorio de Asesorias</h1>
              <div className="col-12 searchUbi">
                <div className="searchdiv">
                  <form method="GET" id="ubiSearch">
                    <label htmlFor="ubicacion">Buscar por ciudad: </label>
                    <input type="name" id="buscador-ubi" name="ubicacion"></input>
                    <button type="submit" className="searchbutton"><i className="fa fa-search" aria-hidden="true"></i></button>
                  </form>
                  <ul id="autocomplete-list"></ul>
                  </div>
                  <div className="searchdiv">
                  <form method="GET" id="buscador-name">
                    <label className="labelBuscador">Buscar por asesoría: </label>
                    <input type="name" id="buscador-nombre"></input>
                    <button type="submit" className="searchbutton"><i className="fa fa-search" aria-hidden="true"></i></button>
                  </form>
                  <ul id="autocomplete-list-name"></ul>
                  </div>
              </div>
              
              <div className="filtre col-12 servicios">
                <big>Servicios</big>
                <hr />
                <div className="content">
                <ul id="servicios_list">
                {servicios.map(servicio => {
                  return (
                    <li key={servicio}>
                      <label htmlFor={servicio}>{servicio}</label>
                      <input type="checkbox" className="servicio" name={servicio} key={servicio} />
                    </li>
                  );
                })}
                </ul>
                </div>
              </div>
              <div className="filtre col-12 industrias">
                <big>Sector</big>
                <hr />
                <div className="content">
                <ul id="industria_list">
                {industrias.map(industria => {
                  return (
                    <li key={industria}>
                      <label htmlFor={industria}>{industria}</label>
                      <input type="checkbox" className="industria" name={industria} key={industria} />
                    </li>
                  );
                })}
                </ul>
                </div>
              </div>
              <div className="filtre col-12 asesoria-partner">
              <big>Tipo</big>
                <hr />
                <div className="content">
                <label htmlFor="asesoriaPartner">Asesoria Partner</label>
                <input type="checkbox" name="asesoriaPartner" id="asesoriaPartner" key="asesoriaPartner"/>
              </div>
              </div>
            </aside>
            <div className="col-md-8 col-12 asesoriaslist">
              <div className="wrapper">
                <header className="header-blog-home">
                  <div className="titulares">
                    <h2>{category.name}</h2>
                  </div>
                </header>
                  
              </div>
              <div id="list-posts">
                  <FilterAsesorias posts={posts} page={currentPage} ubicacion={currentUbi} partner={isPartner} industrias={currentIndustries} servicios={currentServices} postName={currentPostName} />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default Asesorias;

export const AsesoriasQuery = graphql`
  query CurrentAsesoriasQuery( $databaseId: Int!, $databaseIdPage: Int!) {
    allWpPost(
      filter: {
        categories: {
          nodes: { elemMatch: { databaseId: { eq: $databaseId } } }
        }
      }
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        databaseId
        title
        content
        excerpt
        slug
        date(formatString: "DD/MM/yyyy")
        seo {
          title
          metaDesc
        }
        categories {
          nodes {
            databaseId
            name
            slug
            ancestors {
              nodes {
                databaseId
                slug
                uri
                name
              }
            }
          }
        }
        featuredImage {
          node {
            altText
            srcSet
            sourceUrl
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        industrias {
          fieldGroupName
          industrias
        }
        asesorias {
          servicios
          fieldGroupName
        }
        ubicacion {
          fieldGroupName
          ubicacion
        }
        asesoria_partner {
          fieldGroupName
          partner
        }
        asesoria_data {
          direccion
          email
          telefono
          web
          fieldGroupName
        }
      }
      
    }
    wpCategory(databaseId: { eq: $databaseId }) {
      slug
      name
      uri
      ancestors {
        nodes {
          slug
          name
        }
      }
      seo {
        canonical
        title
        metaDesc
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        opengraphPublisher
        opengraphImage {
          altText
          sourceUrl
          title
          mediaDetails {
            height
            width
          }
        }
        twitterDescription
        twitterTitle
        twitterImage {
          altText
          mediaDetails {
            height
            width
          }
          sourceUrl
        }
      }
    }
    wpPage(databaseId: { eq: $databaseIdPage }) {
      slug
      date
      uri
      modified
      locale {
        id
      }

      seo {
        canonical
        title
        metaDesc
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        opengraphPublisher
        opengraphImage {
          altText
          sourceUrl
          title
          mediaDetails {
            height
            width
          }
        }
        twitterDescription
        twitterTitle
        schema {
          pageType
        }
        twitterImage {
          altText
          mediaDetails {
            height
            width
          }
          sourceUrl
        }
      }
      page_content {
        contentRaw
      }
    }
  }
`;
