import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";

const FilterAsesorias = ({ posts, page, ubicacion, partner, industrias, servicios, postName }) => {
  // console.log("---------------FilterAsesorias----------");
  // console.log("ubicacion --> " + ubicacion);
  // console.log("partner --> " + partner);
  // console.log("industrias --> " + industrias);
  // console.log("servicios --> " + servicios);
  // console.log("postName --> " + postName);
  // console.log("--------------------------------------");
  let postsFiltered = [];
  let countFilter = 0;
  if (partner !== "0") countFilter++;
  if (ubicacion !== "") countFilter++;
  if (industrias !== "") countFilter++;
  if (servicios !== "") countFilter++;
  if (postName !== "") countFilter++;
  if (partner === "0" && ubicacion === "" && industrias === "" && servicios === "" && postName === "") {
    postsFiltered = posts;
  } else {
    posts.forEach(post => {
      let checkFilter = 0;
      if (partner !== "" && partner !== "0") {
        if (post.asesoria_partner.partner === "Partner") {
          checkFilter++;
          // postsFiltered.push(post);
        }
      }
      if (ubicacion !== "") {
        if (post.ubicacion.ubicacion.includes(ubicacion)) {
          if (!postsFiltered.includes(post)) {
            checkFilter++;
          // postsFiltered.push(post);
          }
        }
      }
      if (industrias !== "") {
        const allIndustrias = decodeURIComponent(industrias).split(",");
        // let countIndustria = 0;
        // let allIndustria = 0;
        let hasIndustrie = false;
        allIndustrias.forEach(function (industria) {
          // allIndustria = allIndustria + 1;
          if (post.industrias.industrias.includes(industria.trim())) {
            // countIndustria = countIndustria + 1;
            hasIndustrie = true;
          }
        });
        // if (allIndustria === countIndustria) {
        if (hasIndustrie) {
          if (!postsFiltered.includes(post)) {
            checkFilter++;
            // postsFiltered.push(post);
          }
        }
      }
      if (servicios !== "") {
        const allServices = decodeURIComponent(servicios).split(",");
        // let countService = 0;
        // let allService = 0;
        let hasService = false;
        allServices.forEach(function (service) {
          // allService = allService + 1;
          if (post.asesorias.servicios.includes(service.trim())) {
            // countService = countService + 1;
            hasService = true;
          }
        });
        // if (countService === allService) {
        if (hasService) {
          if (!postsFiltered.includes(post)) {
            checkFilter++;
            // postsFiltered.push(post);
          }
        }
      }
      if (postName !== "") {
        if (post.title.toLowerCase().includes(postName.toLowerCase())) {
          if (!postsFiltered.includes(post)) {
            checkFilter++;
            // postsFiltered.push(post);
          }
        }
      }
      if (checkFilter === countFilter) {
        postsFiltered.push(post);
      }
    });
  }
  const postsToView = [];
  const firstPost = (page * 8) - 8;
  let contadorPosts = 1;
  for (let i = firstPost; i < postsFiltered.length; i++) {
    if (contadorPosts === 8) {
      break;
    }
    postsToView.push(postsFiltered[i]);
    contadorPosts++;
  }
  return (
        <ul>
        {postsToView.map(post => {
          // console.log(post);
          let mainImage = null;
          if (post.featuredImage !== null &&
            post.featuredImage.node !== null &&
            post.featuredImage.node.localFile !== null) {
            mainImage = post.featuredImage.node.localFile.childImageSharp.fluid;
            // mainImage.aspectRatio= 16 / 9;
            mainImage.sizes = "(max-width: 540px) 100px,(max-width: 1024px) 400px, (max-width: 1200px) 300px, 800px";
          }
          return (
            <li key={post.databaseId} className="resultAsesoria">
               
                {mainImage!== null?
                (
                  <article className={post.asesoria_partner.partner === "Partner" ? "partner" : "no-partner"}>
                    <Link to={`/es/asesorias/${post.slug}`} className="col-3">
                      {mainImage !== null ? (
                        <Img
                          itemProp="image"
                          fluid={mainImage}
                          alt={post.featuredImage.node.altText}
                          className="miniature"
                        />
                      ) : null} 
                    </Link>
                    <div className="col-9 content">
                    <Link to={`/es/asesorias/${post.slug}`}><h3>{post.title}</h3></Link>
                    
                    <div className="ubicacion">
                        <h4>{post.ubicacion.ubicacion}</h4>
                        {post.asesoria_partner.partner === "Partner" ? <small><b>Asesoria Partner</b></small> : null }
                    </div>
                    <div className="asesoria-content" dangerouslySetInnerHTML= { { __html: post.excerpt } } />
                    </div>
                    </article>
                )
                :
                (
                  <article className={post.asesoria_partner.partner === "Partner" ? "partner" : "no-partner"}>
                    <div className="col-12 content">
                    <Link to={`/es/asesorias/${post.slug}`}><h3>{post.title}</h3></Link>
                    
                    <div className="ubicacion">
                        <h4>{post.ubicacion.ubicacion}</h4>
                        {post.asesoria_partner.partner === "Partner" ? <small><b>Asesoria Partner</b></small> : null }
                    </div>
                    <div className="asesoria-content" dangerouslySetInnerHTML= { { __html: post.excerpt } } />
                    </div>
                  </article>
                )
                }
            </li>
          );
        })}
        </ul>
  );
};
export default FilterAsesorias;
